import {
    ORDER_PENDING,
    ORDER_ERROR,
    REFRESH_BASKET,
    CLEAR_BASKET,
    ORDER_CHANGE_TYPE, ACCEPT_RULES, CART_INIT_DATA, ORDER_STORE_UPDATE, ORDER_INVALID
} from './actions'
import {emptyOrValue} from "../common/StringHelper";
import StorageStore from "../common/storageStore";


let contact = {}
const initialState = {
    id: null,
    reference_id: null,
    // address: {
    //     city: '',
    //     street: '',
    //     floor: '',
    //     build_nr: '',
    //     flat_nr: '',
    //     description: ''
    // },
    payment_method_id: null,
    payment_method_name: null,
    total_paid: null,
    contact: contact,
    tax_id_no: '',
    custom_fields: {},
    number: '',
    items: [
    ],
    promotions: [
    ],
    payment_token: null,
    payment_settings: {

    },
    estimated_preparation_at: '',
    pickup_at: '',
    type: null,
    coupon: null,
    deliveryFee: 0,
    total: undefined,
    discount_money: 0,
    status: null,
    payment_status: null,
    sub_total: undefined,
    pending: true,
    error: null,
    errors: [],
    accepted_rules: [],
    isValid: true,
    contactStorage: {}
}

const refreshPrice = (state) => {
    state.delivery_fee = null;
}
const cartReducer= (state = initialState,action)=>{
    switch(action.type) {
        case ORDER_PENDING:
            return {
                ...state,
                pending: true
            }
        case ORDER_ERROR:
            return {
                ...state,
                pending: false,
                // isValid: false,
                errors: action.errors
            }
        case ORDER_INVALID:
            return {
                ...state,
                pending: false,
                isValid: false,
                errors: action.errors
            }
        case CART_INIT_DATA:
            if(action.contact){
                initialState.contact = action.contact;
            }
            return {
                ...state,
                ...initialState
            }
        case ACCEPT_RULES:
            return {
                ...state,
                accepted_rules: action.accepted_rules,
            }
        case CLEAR_BASKET:
            return{
                ...initialState,
                items: [],
                id: null,
                reference_id: null,
                total : undefined,
                pending: false,
                address: initialState.address,
                contact: initialState.contact,
                custom_fields: initialState.custom_fields,
                total_paid: null,
                number: initialState.number,
                estimated_preparation_at: initialState.estimated_preparation_at,
                pickup_at: initialState.pickup_at,
                discount_money: null,
                payment_method_id: null,
                payment_method_name: null,
            }
            break;
        case REFRESH_BASKET:
            let order = action.order;
            if(order === null || order === undefined || order.id === undefined || order.id === null){
                let newState = initialState;
                if(order !== null){
                    if(order.type){
                        newState.type = order.type;
                    }
                    if(order.number !== null){
                        newState.number = order.number;
                    }
                    if(order.estimated_preparation_at !== null){
                        newState.estimated_preparation_at = order.estimated_preparation_at;
                    }
                    if(order.pickup_at !== null){
                        newState.pickup_at = order.pickup_at;
                    }

                    let newContact = order.contact;
                    if(newContact && newContact.email){
                        let contact = state.contact;
                        contact.email = newContact.email;
                        newState.contact = contact;
                        const contactCopy = Object.assign({}, contact);
                        contactCopy.comment = "";
                        StorageStore.setItem('contact', JSON.stringify(contactCopy));
                    }
                }

                newState.pending = false;
                // newState.contact = state.contactStorage;
                return {
                    ... state,
                    ... newState
                };
            }
            let newTotal = order.total_money;
            let items = order.items;
            let id = order.id;
            // if(state.contact == null || state.contact == {}){
            //     state.contact = action.order.contact;
            // }
            // if(state.address == null || state.address == {}){
            //     state.address = action.order.address;
            // }
            // var newAddress = order.address;
            // let newContact = order.contact;
            // let address = state.address;
            // let contact = {};
            // if(newContact != undefined){
            //     contact.name = emptyOrValue(newContact.name);
            //     contact.email = emptyOrValue(newContact.email);
            //     contact.phone = emptyOrValue(newContact.phone);
            //     contact.comment = emptyOrValue(newContact.comment);
            //     StorageStore.setItem('contact', JSON.stringify(contact));
            // }
            // if(newAddress != undefined && state.id != order.id){
            //     address = initialState.address;
            //     address.city = emptyOrValue(newAddress.city);
            //     address.street = emptyOrValue(newAddress.street);
            //     address.floor = emptyOrValue(newAddress.floor);
            //     address.build_nr = emptyOrValue(newAddress.build_nr);
            //     address.flat_nr = emptyOrValue(newAddress.flat_nr);
            //     address.zip_code = emptyOrValue(newAddress.zip_code);
            // }

            let newContact = order.contact;
            let contact = state.contact;
            // if(contact === undefined || contact === null){
                if(newContact !== undefined){
                    contact.name = emptyOrValue(newContact.name);
                    contact.email = emptyOrValue(newContact.email);
                    contact.phone = emptyOrValue(newContact.phone);
                    contact.comment = emptyOrValue(newContact.comment);
                    contact.provider = emptyOrValue(newContact.provider);
                    contact.referenceId = emptyOrValue(newContact.referenceId);
                    // if(!contact.name){
                    //     contact.name = emptyOrValue(newContact.name);
                    // }
                    // if(!contact.email){
                    //     contact.email = emptyOrValue(newContact.email);
                    // }
                    // if(!contact.phone){
                    //     contact.phone = emptyOrValue(newContact.phone);
                    // }
                    // if(!contact.comment){
                    //     contact.comment = emptyOrValue(newContact.comment);
                    // }
                }
            // }
            let customFields = state.custom_fields;
            let newCustomFields = order.custom_fields;
            if(newCustomFields !== undefined){

                for (var key in newCustomFields) {
                    var value = customFields[key];
                    if(value === undefined || !value){
                        customFields[key] = newCustomFields[key];
                    }
                }
                // customFields = newCustomFields;
            }

            const contactCopy = Object.assign({}, contact);
            contactCopy.comment = "";
            // delete contactCopy.comment;
            StorageStore.setItem('contact', JSON.stringify(contactCopy));
            let deliveryFee = 0;
            if(order.delivery_fee_money){
                deliveryFee = order.delivery_fee_money;
            }
            let payment_method_id = order.payment_method_id ?order.payment_method_id : state.payment_method_id;
            let payment_method_name = order.payment_method_name ?order.payment_method_name : state.payment_method_name;
            let couponCode = null;
            if(order.coupon !== undefined){
                couponCode = order.coupon.code;
            }
            let couponId = order.coupon ? order.coupon.id : undefined;


            if(order.accept_marketing && !state.accepted_rules.includes("acceptMarketing")){
                state.accepted_rules.push("acceptMarketing");
            }
            return{
                ...state,
                items: items,
                reference_id: order.reference_id,
                id: id,
                total : newTotal,
                pending: false,
                isValid: true,
                tax_id_no: order.tax_id_no,
                status: order.status,
                number: order.number,
                estimated_preparation_at: order.estimated_preparation_at,
                pickup_at: order.pickup_at,
                payment_status: order.payment_status,
                // address: address,
                contact: contact,
                custom_fields: customFields,
                promotions: order.promotions,
                type: order.type,
                coupon: couponCode,
                coupon_id: couponId,
                total_paid: order.total_paid_money,
                discount_money: order.discount_money,
                sub_total: order.sub_total_money,
                delivery_fee: deliveryFee,
                sub_total_delivery_fee: order.sub_total_delivery_fee_money,
                discount_delivery_fee: order.discount_delivery_fee_money,
                payment_method_id: payment_method_id,
                payment_method_name: payment_method_name
            }
        // case GET_ORDER:
        //     return{
        //         ...state,
        //         items: action.order.items,
        //         id: action.order.id,
        //         total : action.order.total_money.amount,
        //         pending: false
        //     }
        case ORDER_CHANGE_TYPE:
            return{
                ...state,
                type: action.orderType
            }
            break;
        case ORDER_STORE_UPDATE:
            let type = state.type;

            let initType = null;

            if(action.data.default_order_type){
                initType = action.data.default_order_type;
            }
            // if(action.data.delivery){
            //     initType = "DELIVERY";
            // }else if(action.data.takeaway){
            //     initType = "PICK_UP";
            // }else if(action.data.dine_in){
            //     initType = "DINE_IN";
            // }else if(action.data.room_service){
            //     initType = "ROOM_SERVICE";
            // }
            initialState.type = initType;
            if(type === null){
                type = initType;
            }
            return{
                ...state,
                type: type
                // type: action.orderType
            }
            break;
        default:
            return state;
    }
}

export default cartReducer;
export const getCartTotal = state => state.total;
export const getCartSubTotal = state => state.sub_total;
export const getCartDeliveryFee = state => state.delivery_fee;
export const getNumberItems = state => state.items.length;
export const getItems = state => state.items;
export const getOrderPending = state => state.pending;
export const getAddress = state => state.address;
export const getNumber = state => state.number;
export const getContact = state => state.contact;
export const getOrderCustomFields = state => state.custom_fields;
export const getCart = state => state;
export const getOrderError = state => state.error;
export const getOrderValid = state => state.isValid;
export const getOrderErrors = state => state.errors;
export const getOrderType = state => state.type;
export const getAcceptedRules = state => state.accepted_rules;
export const getCartPromotions = state => state.promotions
export const getPickDate = state => {
    return state.type === 'DELIVERY' || state.type === 'PICK_UP' || state.type === 'ROOM_SERVICE'  || state.type === 'DINE_IN' ? state.pickup_at : "";
};