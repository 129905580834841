import React, {useState} from 'react';
import {connect} from "react-redux";
import {getCart} from "../../../application/cart/reducer";
import {withTranslation} from "react-i18next";
import Money from "../../common/Money";
import {ReactComponent as PlusRemoveIco} from "../../../images/icons/plus-remove.svg";
import {removePromotion} from "../../../application/cart/cartService";
import {getPromotions} from "../../../application/menu/reducer";
import PromotionInfoModal from "../../common/PromotionInfoModal";

const CartPromotions = (props) => {
    const t = props.t;
    const [loading, setLoading] = useState(false);
    const [promotionInfoModal, setPromotionInfoModal] = useState(undefined);

    const remove = async (orderPromotion) => {

        setLoading(true);
        try {
            await props.dispatch(removePromotion(orderPromotion.id));
        } catch (e) {
            console.log(e);
        }
        setLoading(false)

    }

    const showPromotionInfo = async (promotion) => {
        setPromotionInfoModal(promotion);
    }
    let sortedPromotions = props.cart.promotions.sort((a, b) => a.name.localeCompare(b.name))
    // sortedPromotions = sortedPromotions.filter(x => x.type == "CREDIT");
    return (
        <>
            {promotionInfoModal && (
                <PromotionInfoModal name={promotionInfoModal.name} promotionId={promotionInfoModal.promotion_id}
                                    onHide={() => setPromotionInfoModal(undefined)} onShow={() => {
                }}/>
            )}
            <div className={"container cart-promotions"}>
                {sortedPromotions.map(promotion => {
                    if(promotion.type == "TIP") return null;
                    let isActive = promotion.value_money !== undefined && promotion.value_money.amount > 0;
                    return (
                        <div
                            className={"btn btn-promotion btn-spinner" + (loading ? ' loading' : '') + (!isActive ? ' btn-danger' : '')}
                            key={promotion.id}>
                            <div className={"content"} onClick={(e) => {
                                e.preventDefault();
                                if (e.target.classList.contains('icon')) {
                                    remove(promotion);
                                } else {
                                    showPromotionInfo(promotion);
                                }
                            }}>
                                {promotion.type == "CREDIT" ? (
                                    <>{t("components.cart.promotions.customer_wallet")}
                                        {/*: {promotion.name}*/}
                                    </>
                                ) : (
                                    <>{promotion.name}</>
                                )}

                                <small>
                                    {isActive ? (
                                        <>
                                            <Money value={promotion.value_money}/>
                                            {promotion.max_value_money && (
                                                <>, {t("components.cart.promotions.available")}: <Money
                                                    value={promotion.max_value_money}/></>
                                            )}
                                        </>
                                    ) : (
                                        <>

                                         <div className={"text-danger"}>
                                             Koszyk nie spełnia warunków promocji
                                         </div>
                                            <a className={"text-success"}>
                                            Dowiedz się więcej
                                         </a>
                                        </>
                                    )}
                                </small>
                            </div>
                            <div className={"icon"} onClick={(e) => {
                                e.preventDefault();
                                remove(promotion);
                            }}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                <PlusRemoveIco/>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )

}
const mapStateToProps = (state) => {
    return {
        cart: getCart(state.cart),
        promotions: getPromotions(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartPromotions))