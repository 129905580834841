import ReactGtag from '../../lib/react-gtag/index'
import StorageStore from "./storageStore";
import * as CookieConsent from "vanilla-cookieconsent";

class Analytics {

    static initialized = false;
    static goorderAnalyticsId = null;
    static goorderAnalyticsInitialized = false;
    static shopAnalyticsId = null;
    static googleAnalyticIds = [];

    static updateConsent() {
        ReactGtag.consent(this.getCookieConsent());
    }

    static getCookieConsent() {
        let cookie = CookieConsent.getCookie();
        let categories = [];
        if (cookie && cookie.categories) {
            categories = cookie.categories;
        }
        if(!cookie || cookie.categories === undefined){
            return undefined;
        }
        let data = {
            'ad_storage': categories.includes("marketing") ? 'granted' : 'denied',
            'ad_user_data': categories.includes("marketing") ? 'granted' : 'denied',
            'ad_personalization': categories.includes("marketing") ? 'granted' : 'denied',
            'analytics_storage': categories.includes("analytics") ? 'granted' : 'denied',
        };
        return data;
    }

    static isAcceptCookies = () => {
        let cookie = CookieConsent.getCookie();
        return cookie && cookie.categories && cookie.categories.includes("marketing");
    }
    static isExist = () => {
        return this.initialized && (this.goorderAnalyticsId || (this.googleAnalyticIds && this.googleAnalyticIds.length > 0));
    }

    static checkInitialized() {
        // if(!this.isAcceptCookies()) return;
        if (!this.goorderAnalyticsInitialized) {
            if(this.goorderAnalyticsId){
                if (this.goorderAnalyticsId.includes("GTM-")) {
                    // ReactGtag.gtag('consent', 'default', this.getCookieConsent());
                    ReactGtag.initializeTagManager(this.goorderAnalyticsId, this.getCookieConsent())
                } else {
                    // ReactGtag.gtag('consent', 'default', this.getCookieConsent());
                    ReactGtag.initialize(this.goorderAnalyticsId, {
                        send_page_view: false
                    }, this.getCookieConsent());
                }
                this.goorderAnalyticsInitialized = true;
            }
        }
        if (!this.initialized) {
            this.googleAnalyticIds.forEach(x => {
                if (x.includes("GTM-")) {
                    ReactGtag.initializeTagManager(x, this.getCookieConsent())
                } else {
                    ReactGtag.initialize(x, {
                        send_page_view: false
                    }, this.getCookieConsent());
                    // // ReactGtag.gtag('consent', 'default', this.getCookieConsent());
                    // ReactGtag.gtag('config', x, {
                    //     send_page_view: false,
                    // }, this.getCookieConsent());
                }
            })
            this.initialized = true;
        }
    }

    static initGA(goorderAnalyticsId) {
        this.goorderAnalyticsId = goorderAnalyticsId;
        this.checkInitialized();
        // if (!this.isAcceptCookies()) return;
        // ReactGtag.initialize(goorderAnalyticsId, {
        //     send_page_view: false
        // }, this.getCookieConsent());
    }

    static addGA(googleAnalyticsId) {
        if (googleAnalyticsId.includes("GTM-")) {
            // ReactGtag.initializeTagManager(googleAnalyticsId)
            this.googleAnalyticIds.push(googleAnalyticsId);
        } else {
            this.shopAnalyticsId = googleAnalyticsId;
            this.googleAnalyticIds.push(googleAnalyticsId);
            // if (!this.isAcceptCookies()) return;
            // ReactGtag.gtag('config', googleAnalyticsId, {
            //     send_page_view: false,
            // });
        }
        this.initialized = false;
        this.checkInitialized();
    }

    static pageView(path) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if(!this.isExist()) return;
        ReactGtag.gtag('event', 'page_view', {
            'page_title': path,
            'page_path': path
        });
    }

    static payPurchase(order) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if(!this.isExist()) return;
        const isPurchaseAlready = StorageStore.getItem(`analytics_purchase_pay_${order.id}`);
        if (isPurchaseAlready) return;

        var items = [];
        for (var i = 0; i < order.items.length; i++) {
            var orderDataItem = order.items[i];
            var item = {
                id: orderDataItem.id,
                name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                currency: orderDataItem.total_money.currency,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var purchaseData = {
            "transaction_id": order.reference_id ? order.reference_id : order.id,
            // "affiliation": "Google online store",
            "value": order.total_money.amount,
            "currency": order.total_money.currency,
            "tax": 1.23,
            "shipping": 0,
            // "tax": 1.24,
            // "shipping": data.shipmentPrice,
            "items": items,
        };
        ReactGtag.gtag('event', 'purchase_pay', purchaseData);
        StorageStore.setItem(`analytics_purchase_pay_${order.id}`, "true");
    }

    static purchase(order) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if(!this.isExist()) return;
        const isPurchaseAlready = StorageStore.getItem(`analytics_purchase_${order.id}`);
        if (isPurchaseAlready) return;

        var items = [];
        for (var i = 0; i < order.items.length; i++) {
            var orderDataItem = order.items[i];
            var item = {
                id: orderDataItem.id,
                name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                currency: orderDataItem.total_money.currency,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var purchaseData = {
            "transaction_id": order.reference_id ? order.reference_id : order.id,
            // "affiliation": "Google online store",
            "value": order.total_money.amount,
            "currency": order.total_money.currency,
            "tax": 1.23,
            "shipping": 0,
            // "tax": 1.24,
            // "shipping": data.shipmentPrice,
            "items": items,
        };
        ReactGtag.gtag('event', 'purchase', purchaseData);
        StorageStore.setItem(`analytics_purchase_${order.id}`, "true");
    }

    static async addToCart(orderDataItem) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if(!this.isExist()) return;
        let data = {
            currency: "PLN",
            value: orderDataItem.total_price,
            items: [
                {
                    id: orderDataItem.item_id,
                    name: orderDataItem.name,
                    price: orderDataItem.total_price,
                    quantity: orderDataItem.quantity
                }
            ]
        }

        ReactGtag.ecommerce('add_to_cart', data);
    }

    static async removeFromCart(orderDataItem) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if(!this.isExist()) return;
        let value = orderDataItem.total_money ? orderDataItem.total_money.amount : undefined;
        let data = {
            currency: "PLN",
            value: value,
            items: [
                {
                    id: orderDataItem.item_id,
                    name: orderDataItem.name,
                    price: value,
                    quantity: orderDataItem.quantity
                }
            ]
        }

        ReactGtag.ecommerce('remove_from_cart', data);
    }

    static async viewItem(item) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if(!this.isExist()) return;
        let value = item.price ? item.price.amount : undefined;
        let data = {
            currency: 'PLN',
            value: value,
            items: [{
                item_id: item.id,
                item_name: item.name,
                value
            }]
        }

        ReactGtag.ecommerce('view_item', data);
    }


    static async selectItem(item) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if(!this.isExist()) return;
        let value = item.price ? item.price.amount : undefined;
        let data = {
            currency: 'PLN',
            value: value,
            items: [{
                item_id: item.id,
                item_name: item.name,
                value
            }]
        }

        ReactGtag.ecommerce('select_item', data);
    }

    static async addToCartNewOrder(order) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if(!this.isExist()) return;
        var items = [];
        for (var i = 0; i < order.items.length; i++) {
            var orderDataItem = order.items[i];
            var item = {
                item_id: orderDataItem.item_id,
                item_name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var data = {
            "transaction_id": order.reference_id ? order.reference_id : order.id,
            // "affiliation": "Google online store",
            "value": order.total_money.amount,
            "currency": order.total_money.currency,
            "tax": 1.23,
            "items": items,
        };
        ReactGtag.ecommerce('add_to_cart', data);
    }

    static async beginCheckout(cart) {
        if (!this.isAcceptCookies()) return;
        this.checkInitialized();
        if(!this.isExist()) return;
        if (!cart) return;
        var items = [];
        for (var i = 0; i < cart.items.length; i++) {
            var orderDataItem = cart.items[i];
            var item = {
                item_id: orderDataItem.item_id,
                item_name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var data = {
            "transaction_id": cart.reference_id ? cart.reference_id : cart.id,
            // "transaction_id": cart.id,
            // "affiliation": "Google online store",
            "value": cart.total.amount,
            "currency": cart.total.currency,
            "tax": 1.23,
            "items": items,
        };
        ReactGtag.ecommerce('begin_checkout', data);
    }

}

export default Analytics;